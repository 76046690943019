<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Notices </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  Notices
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    @click="createNotice"
                      class="mt-1 btn btn-block btn-primary"
                      style="color:#fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add new
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Name"
                        v-model="search.name"
                        v-on:keyup.enter="searchNotices"
                        @input="search.name = $event !== null ? $event : ''"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-select
                        label="User Type"
                        item-text="text"
                        item-value="value"
                        :items="user_types"
                        v-model="search.user_type"
                        v-on:keyup.enter="searchNotices"
                        @input="search.user_type = $event !== null ? $event : ''"
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                            ripple
                            @click="allSelect"
                        >
                          <v-list-item-action>
                            <v-icon :color="search.user_type.length > 0 ? 'indigo darken-4' : ''">
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select all
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-1"></v-divider>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="search.visible_from_date"
                            chips
                            small-chips
                            label="Visible From Date"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="search.visible_from_date"
                          outlined
                          dense
                          no-title
                          @input="menu = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select
                        label="Status"
                        v-model="search.is_active"
                        v-on:keyup.enter="searchNotices"
                        @input="search.is_active = $event !== null ? $event : ''"
                        :items="status"
                        item-text="name"
                        item-value="value"
                        clearable
                        outlined
                        dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-btn
                        @click="searchNotices"
                        class="mt-1 btn btn-block btn-primary"
                        style="color: #fff;"
                        :loading="loading"
                    >
                      <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </v-row>
            </div>
            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3" :class="sortedClass('name')" @click="sortBy('name')">Name</th>
                  <th class="px-3">User Type</th>
                  <th class="px-3">Visible From Date</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <template>
                    <tr v-for="(notice, index) in sortedItems" :key="index" >
                      <td class="px-3">
                        <a @click="editNotice(notice)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                          {{ notice.name }}
                        </a>
                      </td>
                      <td class="px-3">
                        <ul v-if="notice.user_types">
                          <li v-for="(user,index) in notice.user_types" :key="index">
                            {{ user }}
                          </li>
                        </ul>
                        <span class="font-weight-bolder text-dark-65" v-else>
                          NA
                        </span>
                      </td>

                      <td class="px-3">
                        {{ notice.formatted_date != null ? notice.formatted_date : '--' }}
                      </td>

                      <td class="px-3">
                        <span class="badge"
                              v-bind:class="{ 'badge-success': notice.is_active, 'badge-danger': !notice.is_active }"
                        >{{ notice.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-left">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click="editNotice(notice)">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteNotice(notice.id)">
                                <span class="navi-icon">
                                   <i class="fas fa-trash"></i>
                                </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="notices.length == 0">
                      <td class="text-center font-weight-bold px-3" colspan="6">No Data Found</td>
                    </tr>
                </template>

              </table>
              <b-pagination
                  v-if="notices.length > 0"
                  class="pull-right mt-7"
                  @input="getAllNotices"
                  v-model="page"
                  :disabled="loading"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
            <!-- table ends -->
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllNotices"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import NoticeService from "@/services/cms/notice/NoticeService";
import CreateAndUpdate from "@/view/pages/view/cms/notice/CreateAndUpdate";

const notice = new NoticeService();
export default {
  components: {
    CreateAndUpdate,
  },
  data() {
    return{
      loading: false,
      menu: false,
      total: null,
      perPage: null,
      page: null,
      notices:[],
      search:{
        name:'',
        user_type: [],
        visible_from_date: '',
        is_active: '',
      },
      sort: {
        key: '',
        isAsc: false
      },
      user_types:[
        { value: 'account_holder', text: 'Enroller' },
        { value: 'examiner', text: 'Examiner' },
        { value: 'supervisor', text: 'Supervisor' },
        { value: 'online_marker', text: 'Online marker' },
        { value: 'candidate', text: 'Candidate' },
        { value: 'federal', text: 'Federal' },
        { value: 'states', text: 'States' },
        { value: 'admin', text: 'Admin' },
      ],
      status: [
          { name: 'Active', value: '1'  },
          { name: 'Inactive', value: '0' }
      ],
    }
  },
  computed: {
    sortedItems() {
      const list = this.notices.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    },
    allSelectType () {
      return this.search.user_type.length === this.user_types.length
    },
    selectType () {
      return this.search.user_type.length > 0 && !this.allSelectType
    },
    icon () {
      if (this.allSelectType) return 'mdi-close-box'
      if (this.selectType) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    createNotice(){
      this.$refs['create-and-update'].createNotice();
    },
    editNotice(notice){
      this.$refs['create-and-update'].editNotice(notice);
    },
    resetSearch(){
      this.search = {};
      this.getAllNotices();
    },
    allSelect () {
      this.$nextTick(() => {
        if (this.allSelectType) {
          this.search.user_type = []
        } else {
          this.search.user_type = [];
          this.user_types.forEach(type => {
            this.search.user_type.push(type.value);
          });
        }
      })
    },
    getAllNotices(){
      this.loading = true;
        notice
        .paginate(this.search, this.page)
        .then(response => {
            this.notices = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$snotify.error("Oops something went wrong");
        });
    },
    searchNotices(){
      this.getAllNotices();
    },
    deleteNotice(id){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            notice
              .delete(id)
              .then((response) => {
                this.getAllNotices()
                this.$snotify.success("Notice deleted ");
              })
              .catch((err) => {
                this.$snotify.error("Oops something went wrong");
              });
            }
        }
      });
    }
  },
  mounted() {
    this.getAllNotices();
  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>